import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { useEffect, useRef, useState } from 'react';
import states from './states.json';

export default function SmallMap({ map }) {
    const mapContainer = useRef(null);
    const [lng] = useState(133.12899358447265);//-25.047686389207684, 131.4224767244738
    const [lat] = useState(-25.445178717575352);
    const [zoom] = useState(3);
    const [API_KEY] = useState('rp92M4LXFSTR0G4mm74T');

    useEffect(() => {
        if (map.current) return; // stops map from intializing more than once
      
        map.current = new maplibregl.Map({
          container: mapContainer.current,
          style: {
            version: 8,
            sources: {
                "states": {
                    'type': 'geojson',
                    'data': states
                }
            },
            layers: [
                {
                  'id': 'state-fills',
                  'type': 'fill',
                  'source': 'states',
                  'layout': {},
                  'paint': {
                      'fill-color': '#1eb854',
                      'fill-opacity': [
                          'case',
                          ['boolean', ['feature-state', 'hover'], false],
                          0.5,
                          0
                      ]
                  }
              },
                {
                    'id': 'state-borders',
                    'type': 'line',
                    'source': 'states',
                    'layout': {},
                    'paint': {
                        'line-opacity': 1,
                        'line-color': '#1eb854',
                        'line-blur': 10,
                        'line-width': 2
                    }
                }
            ]
          },
          center: [lng, lat],
          zoom: zoom,
          interactive: false
        });
      
      }, [API_KEY, lng, lat, zoom]);

      return (
        <div className="map-wrap smallmap">
          <div ref={mapContainer} className="map" />
        </div>
      );
}