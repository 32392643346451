import '../App.css';
import { useEffect, useRef, useState } from 'react';
import { motion } from "framer-motion"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faGhost, faHeart } from '@fortawesome/free-solid-svg-icons';
import Footer from '../components/Footer';

function Target() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
  return (
    <>
    <div className='grid grid-cols-1 min-h-screen py-[10vh] relative text-center md:text-start'>
      <div className="flex px-10 md:px-20 items-center justify-center">
          <div className='prose w-screen'>
            <h1>Sport/Target Shooting</h1>
            <h3>A lifelong sport for all</h3>
            <hr></hr>
            <h2 id='overview'>Overview</h2>
          </div>
      </div>
    </div>
    <Footer />
    </>
  );
}

export default Target;
