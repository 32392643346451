import '../App.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function Footer() {
  return <footer className="footer p-10 bg-base-200 text-base-content">
  <aside>
    <p>Made with <FontAwesomeIcon icon={faHeart} size="3xl" /> for Australia by nobody<br/>Providing web services since 1901</p>
  </aside> 
  <nav>
    <h6 className="footer-title">Licensing</h6> 
    <Link to="/get-licensed/nsw#overview" className="link link-hover">NSW</Link>
    <a className="link link-hover">QLD</a>
    <a className="link link-hover">VIC</a>
    <a className="link link-hover">ACT</a>
  </nav> 
  <nav>
    <h6 className="footer-title invisible">Licensing</h6> 
    <a className="link link-hover">TAS</a>
    <a className="link link-hover">SA</a>
    <a className="link link-hover">NT</a>
    <a className="link link-hover">WA</a>
  </nav> 
  <nav>
    <h6 className="footer-title">Credits</h6> 
    <a className="link link-hover">Icons</a>
  </nav>
  <nav>
    <h6 className="footer-title">Contact</h6> 
    <a className="link link-hover">Email Us</a>
  </nav> 
</footer>;
}

export default Footer;
