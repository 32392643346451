import logo from './logo.svg';
import './App.css';
import Map from './map';
import { useEffect, useRef, useState } from 'react';
import { motion } from "framer-motion"

import target from './target-svgrepo-com.svg'
import ranger from './ranger-svgrepo-com.svg'
import deer from './deer-svgrepo-com.svg'
import doctors from './doctornurse-svgrepo-com.svg'
import teacher from './teacher-svgrepo-com.svg'
import builder from './builder-svgrepo-com.svg'
import old from './old-man-svgrepo-com.svg'
import access from './accessibility-svgrepo-com.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faGhost, faHeart } from '@fortawesome/free-solid-svg-icons';
import Footer from './components/Footer';
import { Link } from 'react-router-dom';

function App() {
  const [hover, setHover] = useState(false)
  const [info, setInfo] = useState({})
  const scrollRef = useRef(null)
  const scroll2Ref = useRef(null)
  const scroll3Ref = useRef(null)

  const jumpToFirstSlide = () => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  }

  const jumpToSecondSlide = () => {
    scroll2Ref.current.scrollIntoView({ behavior: "smooth" });
  }

  const jumpToThirdSlide = () => {
    scroll3Ref.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <><div className='flex flex-row h-screen relative text-center md:text-start'>
        <div className='absolute bottom-5 z-50 w-screen flex justify-center'>
          <motion.div
          whileHover={{ scale: 1.2 }}
          whileTap={{
            scale: 0.8,
            borderRadius: "100%"
          }}>
            <FontAwesomeIcon onClick={jumpToFirstSlide} className='cursor-pointer' icon={faArrowDown} size="2xl" />
          </motion.div>
        </div>
        <div className="flex h-screen px-20 sm:px-10 sm:basis-full md:basis-1/2 items-center justify-center">
          <div>
            { hover && <div className='prose'>
              <h2>{info.state}</h2>
              <strong>
                {info.licenses} licensed shooters.<br></br>
                {info.firearms} registered firearms.
              </strong>
            </div> }
            {
              !hover && <div className='prose'>
              <h1>Not Enough Guns</h1>
              <strong>
                3.4% of the population are licensed shooters.
              </strong>
              <p>
                There are over 897,204 firearm licence holders in Australia.<br/>
                There are almost 4 million firearms registered.
                <sup><a href="https://archive.is/FC8v5#selection-399.0-409.159">[1]</a></sup>
              </p>
              <Link as={HTMLButtonElement} to="/get-licensed" className='btn btn-outline btn-primary'>Join them</Link>
            </div>
            }
          </div>
        </div>
        <div className='flex h-screen justify-center sm:invisible md:basis-1/2 md:visible'>
          <Map setHover={setHover} setInfo={setInfo} />
        </div>
    </div>
    <div className='grid grid-cols-1 h-screen md:grid-cols-2 relative text-center md:text-start' ref={scrollRef}>
      <div className='absolute bottom-5 z-50 w-screen flex justify-center'>
          <motion.div
          whileHover={{ scale: 1.2 }}
          whileTap={{
            scale: 0.8,
            borderRadius: "100%"
          }}>
            <FontAwesomeIcon onClick={jumpToSecondSlide} className='cursor-pointer' icon={faArrowDown} size="2xl" />
          </motion.div>
      </div>
      <div className="flex px-10 md:px-20 items-center justify-center">
          <div className='prose'>
              {/*<h1>We have genuine reasons</h1>
              <h3>Target shooting, hunting, and pest control.</h3>
              <strong>
                We compete for glory, we put food on our plates, and we protect ecosystems.<br/>
                We all have different reasons for owning and using firearms.
          </strong>*/}
            <h1>We have genuine reasons</h1>
            <h3>Whether it's for target shooting, hunting, or pest control, we have legitimate purposes.</h3>
            <p>
            Some of us compete for glory, some rely on hunting to put food on our plates, and others work to protect ecosystems. <strong>Each of us has genuine reasons for owning and using firearms.</strong>
            </p><br/>
            Click on the icons to find out more about each reason.
          </div>
      </div>
      <div className="flex-row md:items-center flex items-start justify-center gap-5">
          <Link to="/sport"><motion.img initial={{rotateY:0}} whileHover={{ scale: 1.2, rotateY: 180 }} width="100rem" height="100rem" className='cursor-pointer' src={target} /></Link>
          <motion.img initial={{rotateY:0}} whileHover={{ scale: 1.2, rotateY: 180 }} width="100rem" height="100rem" className='cursor-pointer' src={deer} />
          <motion.img initial={{rotateY:0}} whileHover={{ scale: 1.2, rotateY: 180 }} width="100rem" height="100rem" className='cursor-pointer' src={ranger} />
      </div>
    </div>
    <div className='grid grid-cols-1 h-screen md:grid-cols-2 relative text-center md:text-start' ref={scroll2Ref}>
      <div className='absolute bottom-5 z-50 w-screen flex justify-center'>
          <motion.div
          whileHover={{ scale: 1.2 }}
          whileTap={{
            scale: 0.8,
            borderRadius: "100%"
          }}>
            <FontAwesomeIcon onClick={jumpToThirdSlide} className='cursor-pointer' icon={faArrowDown} size="2xl" />
          </motion.div>
      </div>
      <div className="flex px-10 md:px-20 items-center justify-center">
          <div className='prose'>
              <h1>Shooters are people like us</h1>
              <h3>Everyday people with a hobby, a passion, a love for firearms. </h3>
              <p>
              We come from all walks of life – doctors, lawyers, teachers, farmers, tradespeople, students, and more.
              </p>
              <p>
                
              </p>
          </div>
      </div>
      <div className="flex-row md:items-center flex items-start justify-center gap-5">
          <motion.img initial={{rotateY:0}} whileHover={{ scale: 1.2, rotateY: 180 }} width="100rem" height="100rem" src={doctors} />
          <motion.img initial={{rotateY:0}} whileHover={{ scale: 1.2, rotateY: 180 }} width="100rem" height="100rem" src={teacher} />
          <motion.img initial={{rotateY:0}} whileHover={{ scale: 1.2, rotateY: 180 }} width="100rem" height="100rem" src={builder} />
      </div>
    </div>
    <div className='grid grid-cols-1 h-screen md:grid-cols-2 relative text-center md:text-start' ref={scroll3Ref}>
      <div className="flex px-10 md:px-20 items-center justify-center">
          <div className='prose'>
              <h1>Open to all</h1>
              <h3>Shooting is a diverse sport that transcends barriers.</h3>
              <p>
                Whether you're aiming for Olympic gold or simply seeking the joy of hitting a target, shooting stands as a sport that knows no bounds.<br/>
                Regardless of age, gender, background, or physical ability, the thrill of handling a rifle is open to all.<br/>
              </p>
          </div>
      </div>
      <div className="flex-row md:items-center flex items-start justify-center gap-5">
          <motion.img initial={{rotateY:0}} whileHover={{ scale: 1.2, rotateY: 180 }} width="100rem" height="100rem" src={old} />
          <motion.img initial={{rotateY:0}} whileHover={{ scale: 1.2, rotateY: 180 }} width="100rem" height="100rem" src={access} />
      </div>
      <div className='col-span-1 md:col-span-2 flex-row md:items-center flex items-start justify-center gap-5'>
        <Link as={HTMLButtonElement} to="/get-licensed" className='btn btn-lg btn-primary'>Become a licensed firearm user!</Link>
      </div>
    </div>
    <Footer />
    </>
  );
}

export default App;
