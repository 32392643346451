import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { useEffect, useRef, useState } from 'react';
import states from './states.json';

export default function Map({ setHover, setInfo }) {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng] = useState(134.59116044135686);
    const [lat] = useState(-25.710199534804968);
    const [zoom] = useState(3);
    const [API_KEY] = useState('rp92M4LXFSTR0G4mm74T');

    useEffect(() => {
        let hoveredStateId = -1;
        if (map.current) return; // stops map from intializing more than once
      
        map.current = new maplibregl.Map({
          container: mapContainer.current,
          style: `https://api.maptiler.com/maps/c518d901-87f8-4de5-a98e-c9e6cf9ba4e9/style.json?key=${API_KEY}`,
          center: [lng, lat],
          zoom: zoom,
          interactive: false
        });

        map.current.on('load', () => {
            map.current.addSource('states', {
                'type': 'geojson',
                'data': states
            });
            map.current.addLayer({
                'id': 'state-fills',
                'type': 'fill',
                'source': 'states',
                'layout': {},
                'paint': {
                    'fill-color': '#627BC1',
                    'fill-opacity': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        1,
                        0.5
                    ]
                }
            });
    
            map.current.addLayer({
                'id': 'state-borders',
                'type': 'line',
                'source': 'states',
                'layout': {},
                'paint': {
                    'line-color': '#627BC1',
                    'line-width': 2
                }
            });

            map.current.on('mousemove', 'state-fills', (e) => {
                if (e.features.length > 0) {
                    if (hoveredStateId >= 0) {
                        map.current.setFeatureState(
                            {source: 'states', id: hoveredStateId},
                            {hover: false}
                        );
                    }
                    hoveredStateId = e.features[0].id;
                    map.current.setFeatureState(
                        {source: 'states', id: hoveredStateId},
                        {hover: true}
                    );
                    setInfo({ 
                        state: e.features[0].properties.STATE_NAME, 
                        firearms: e.features[0].properties.firearms,
                        licenses: e.features[0].properties.licenses
                    })
                    setHover(true)
                }
            });
    
            // When the mouse leaves the state-fill layer, update the feature state of the
            // previously hovered feature.
            map.current.on('mouseleave', 'state-fills', () => {
                console.log(hoveredStateId)
                if (hoveredStateId >= 0) {
                    map.current.setFeatureState(
                        {source: 'states', id: hoveredStateId},
                        {hover: false}
                    );
                }
                hoveredStateId = -1;
                setHover(false)
            });
        })
      
      }, [API_KEY, lng, lat, zoom]);

      return (
        <div className="map-wrap">
          <div ref={mapContainer} className="map" />
        </div>
      );
}