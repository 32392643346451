import '../../App.css';
import { useEffect, useRef, useState } from 'react';
import { motion } from "framer-motion"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faGhost, faHeart } from '@fortawesome/free-solid-svg-icons';
import Footer from '../../components/Footer';

function NSW() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
  return (
    <>
    <div className='grid grid-cols-1 min-h-screen py-[10vh] relative text-center md:text-start'>
      <div className="flex px-10 md:px-20 items-center justify-center">
          <div className='prose w-screen'>
            <h1>NSW Licensing</h1>
            <h3>A step by step guide</h3>
            <hr></hr>
            <h2 id='overview'>Overview</h2>
            <ol className='text-start'>
                <li><a href="#step-1">Complete a firearms safety course</a></li>
                <li><a href="#step-2">Become a member of a shooting or hunting club</a></li>
                <li><a href="#step-3">Apply for your firearms licence</a></li>
                <li><a href="#step-4">Receive photo advice in the mail & get your photo taken at Service NSW</a></li>
                <li><a href="#step-5">Receive licence in the mail</a></li>
            </ol>
            <h2>Are you eligible?</h2>
            <p>You must be a responsible and trustworthy individual who can safely handle firearms without posing a risk to public safety.</p>
            <h3>Personal History</h3>
            <sub>Source: <a href="https://www.police.nsw.gov.au/__data/assets/pdf_file/0016/131164/Info_on_obtaining_a_firearms_lic_FACT_Sheet.pdf">NSW Police Licence FACT Sheet</a></sub>
            <p>
                A licence cannot be issued if a person (in New South Wales or elsewhere):
            </p>
            <ul className='text-start'>
                    <li>Is subject to an Apprehended Violence Order (AVO) or makes application for a firearms licence within a 10
                year period from the expiry of an AVO (unless the AVO has been revoked)</li>
                    <li>Is subject to an Injunction Order issued by the Family Law Court</li>
                    <li>Is subject to an Interim Apprehended Violence Order</li>
                    <li>Is subject to a Provisional Apprehended Violence Order</li>
                    <li>Is subject to a good behaviour bond for a prescribed offence</li>
                    <li>Is subject to a firearms or weapons prohibition order</li>
                    <li>Is a registrable person or corresponding registrable person under the Child Protection (Offenders
                Registration) Act 2000</li>
                    <li>Has been convicted within the last 10 years of an offence prescribed by the regulations</li>
            </ul>
            <h4>Prescribed Offences</h4>
            <p>Conviction for offences involving firearms or weapons, prohibited drugs/plants, 
                fraud/dishonesty/stealing, prescribed restricted substances, terrorism, violence, public order, assaults against law
                enforcement officers, robbery, organised criminal groups and recruitment, riot, affray or an offence of a sexual nature.
            </p>
            <h2 id='step-1'>1. Complete a firearms safety course</h2>
            <p>
                You must complete a firearms safety course before applying for your firearms licence.<br />
                If you are going for your Category A & B licence this would be a longarms safety course.
                <br />
                Most courses will teach you everything you need to know, however it is recommended to study the <a href="https://www.police.nsw.gov.au/__data/assets/pdf_file/0014/133133/Firearms_Users_Guide.pdf">Longarms User Guide.</a>
                <br /><br />
                There are many training providers including but not limited to:
            </p>
            <ul className='text-start'>
                <li><a href="https://ssaansw.org.au/safe-shooting/">SSAA NSW</a></li>
                <li><a href="https://www.sport.nsw.gov.au/firearm-safety-qualification-long-arms-course-booking-terms-and-conditions">Office of Sport NSW</a></li>
                <li><a href="https://firearmtraining.com.au/courses/pre-licence-qualification-course/">Firearm Safety and Training Council</a></li>
            </ul>
            <p>
                You will receive a certificate or receipt of completion that is valid for 12 months.<br />
                It must be provided with your licence application as evidence that you can safely handle firearms.
            </p>
            <h2 id='step-2'>2. Become a member of a shooting or hunting club</h2>
            <p>
                You must join a shooting or hunting club before applying for your firearms licence.<br/>
                You can join a local club or join the <a href="https://membership.ssaa.org.au/new-membership">SSAA.</a><br/>
            </p>
            <h3>What's the SSAA?</h3>
            <p>
                The Sporting Shooters Association of Australia or SSAA is Australia's largest shooting and hunting club with a presence in all states and has great range facilities available to the public.
                <br /><br />
                The easiest route would be to join the SSAA as most small to medium sized clubs will want to sus you out for a while or are full.
            </p>
            <h3>Proof of Membership</h3>
            <p>
                Now that you've joined a shooting or hunting club you will receive some form of proof of membership - this may be a membership card or an email.<br />
                It must be provided with your licence application as evidence that you are a member of an approved shooting or hunting club in NSW.
            </p>
            <h2 id='step-3'>3. Apply for your firearms licence</h2>
            <p>
                <strong>You're almost there!</strong><br />
                This is the last big step, unless there is something wrong with your application.<br/><br/>

                The preferred method to apply for your firearms licence is through the <a href="https://firearms.police.nsw.gov.au/cssp/s/about-firearms-licence?appType=Individual">NSW Police's Gun Safe Portal.</a><br/>
                It's a fairly straight forward process, you need to answer some questions, provide evidence of your safety course completion and provide proof of club membership.
            </p>
            <h3>What is my genuine reason?</h3>
            <p>Your genuine reason is target shooting if you've only joined a shooting club, however if you've also joined a hunting or collecting club, then you can apply with those reasons too.</p>
            <h3>What categories should I apply for?</h3>
            <p>If you're only interested in rifles and shotguns, apply for categories A & B at the same time.</p>
            <h4>Categories</h4>
            <sub>Source: <a href="https://www.police.nsw.gov.au/__data/assets/pdf_file/0015/133134/GR_TABLE.pdf">LICENCE CATEGORIES & GENUINE REASON TABLE</a></sub>
            <p>
                <strong>A:</strong> Air rifles, Rimfire rifle (other than self-loading), Shotgun/rimfire combinations, Shotgun (other than pump action, lever action or self-loading)<br/>
                <strong>B:</strong> Muzzle loading firearms (other than pistols), Centre-fire rifles (other than self-loading), Shotgun/centre-fire combinations, Lever action shotguns with a magazine capacity of no more than 5 rounds<br/>
                <strong>C:</strong> Self-loading rimfire rifles with a magazine capacity of no more than 10 rounds, Self-loading shotguns with a magazine capacity of no more than 5 rounds, Pump action shotguns with a magazine capacity of no more than 5 rounds<br/>
                <strong>D:</strong> Self-loading centre-fire rifles, Self-loading rimfire rifles with a magazine capacity of more than 10 rounds, Self-loading shotguns with a magazine capacity of more than 5 rounds, Pump action shotguns with a magazine capacity of more than 5 rounds, Lever action shotguns with a magazine capacity of more than 5 rounds, Any firearm to which a category C licence applies<br/>
                <strong>H:</strong> Pistols including blank fire pistols and air pistols<br/>
                <sub>C & D are prohibited except for limited or official purposes</sub>
            </p>
                <table className="table-auto">
                    <thead>
                        <tr>
                        <th>Genuine Reason</th>
                        <th>Cat A</th>
                        <th>Cat B</th>
                        <th>Cat C</th>
                        <th>Cat D</th>
                        <th>Cat H</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>Sport/Target Shooting</td>
                        <td>YES</td>
                        <td>YES*</td>
                        <td>YES*</td>
                        <td>NO</td>
                        <td>YES*</td>
                        </tr>
                        <tr>
                        <td>Recreational Hunting/Vermin Control</td>
                        <td>YES</td>
                        <td>YES*</td>
                        <td>NO</td>
                        <td>NO</td>
                        <td>NO</td>
                        </tr>
                        <tr>
                        <td>Primary Production</td>
                        <td>YES</td>
                        <td>YES*</td>
                        <td>YES*</td>
                        <td>NO</td>
                        <td>NO</td>
                        </tr>
                        <tr>
                        <td>Vertebrate Pest Animal Control</td>
                        <td>YES</td>
                        <td>YES*</td>
                        <td>-</td>
                        <td>YES*</td>
                        <td>NO</td>
                        </tr>
                        <tr>
                        <td>Business or Employment</td>
                        <td>YES</td>
                        <td>YES*</td>
                        <td>NO</td>
                        <td>NO</td>
                        <td>YES*</td>
                        </tr>
                        <tr>
                        <td>Rural Occupation</td>
                        <td>YES</td>
                        <td>YES*</td>
                        <td>NO</td>
                        <td>NO</td>
                        <td>NO</td>
                        </tr>
                        <tr>
                        <td>Animal Welfare</td>
                        <td>YES</td>
                        <td>YES*</td>
                        <td>NO</td>
                        <td>NO</td>
                        <td>NO</td>
                        </tr>
                        <tr>
                        <td>Firearms Collection</td>
                        <td>YES</td>
                        <td>YES*</td>
                        <td>YES*</td>
                        <td>YES*</td>
                        <td>YES*</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <sub>* Demonstrate special need. Refer to source for more detail.</sub>
                    </tfoot>
                </table>
                <h3>Submission</h3>
                <p>
                    Once you've submitted your application, NSW Firearms Registry will wait 28 days before reviewing it.<br/>
                    If successful you will receive photo advice in the mail.<br/>
                    If there is an issue with your application the Firearms Registry will reach out to you.
                </p>
                <h2 id='step-4'>4. Get your photo taken at Service NSW</h2>
                <p>
                    Once you receive your photo advice in the mail, you will need to go to Service NSW to get your photo taken for your licence.<br/>
                    You will need to bring your photo advice and required identification with you.
                </p>
                <h2 id='step-5'>5. Receive licence in the mail</h2>
                <p>
                    You're ready to shoot!<br/>
                    Since you don't have any firearms at this point, it's probably good idea to put two Permit to Acquires (PTA) in, one for Category A and another for Category B.<br />
                    Your first PTAs for each category will take a minimum of 28 days to be approved, it's recommended to do these now, rather than later.<br/>
                    Subsequent PTAs may take sub 24 hours or longer to clear.<br/>
                    <strong>You will receive your PTAs digitally!</strong><br/>
                    In the mean time borrow your clubs, range or your mates rifles.
                </p>
          </div>
      </div>
    </div>
    <Footer />
    </>
  );
}

export default NSW;
