import '../App.css';
import { useEffect, useRef, useState } from 'react';
import { motion } from "framer-motion"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faGhost, faHeart } from '@fortawesome/free-solid-svg-icons';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import SmallMap from '../smallmap';

function GetLicensed() {
    const map = useRef(null);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
  return (
    <>
    <div className='grid grid-cols-1 h-screen md:grid-cols-2 relative text-center md:text-start'>
      <div className="flex px-10 md:px-20 items-center justify-center">
            <div className='prose'>
                <h1>Let's get you licensed</h1>
                <h3>Please choose your state</h3>
            </div>
      </div>
      <div className="flex-row md:items-center relative flex items-start justify-center gap-5">
            <div className='w-[125%] scale-75 md:scale-100 md:w-full h-[38rem] md:h-full absolute z-4 bottom-[0rem] md:top-0'>
                <SmallMap map={map}/>
            </div>
            <div className='grid grid-cols-4 gap-4 absolute z-5'>
            <Link onMouseOver={() => {
                map.current.setFeatureState(
                    {source: 'states', id: 0},
                    {hover: true}
                );
            }} onMouseLeave={() => {
                map.current.setFeatureState(
                    {source: 'states', id: 0},
                    {hover: false}
                );
            }} as={HTMLButtonElement} to="/get-licensed/nsw" className='btn btn-outline btn-primary'>NSW</Link>
            <button onMouseOver={() => {
                map.current.setFeatureState(
                    {source: 'states', id: 2},
                    {hover: true}
                  );
            }} onMouseLeave={() => {
                map.current.setFeatureState(
                    {source: 'states', id: 2},
                    {hover: false}
                );
            }} className='btn btn-outline btn-primary'>QLD</button>
            <button onMouseOver={() => {
                map.current.setFeatureState(
                    {source: 'states', id: 1},
                    {hover: true}
                );
            }} onMouseLeave={() => {
                map.current.setFeatureState(
                    {source: 'states', id: 1},
                    {hover: false}
                );
            }} className='btn btn-outline btn-primary'>VIC</button>
            <button onMouseOver={() => {
                map.current.setFeatureState(
                    {source: 'states', id: 7},
                    {hover: true}
                );
            }} onMouseLeave={() => {
                map.current.setFeatureState(
                    {source: 'states', id: 7},
                    {hover: false}
                );
            }} className='btn btn-outline btn-primary'>ACT</button>
            <button onMouseOver={() => {
                map.current.setFeatureState(
                    {source: 'states', id: 5},
                    {hover: true}
                );
            }} onMouseLeave={() => {
                map.current.setFeatureState(
                    {source: 'states', id: 5},
                    {hover: false}
                );
            }} className='btn btn-outline btn-primary'>TAS</button>
            <button onMouseOver={() => {
                map.current.setFeatureState(
                    {source: 'states', id: 6},
                    {hover: true}
                );
            }} onMouseLeave={() => {
                map.current.setFeatureState(
                    {source: 'states', id: 6},
                    {hover: false}
                );
            }}  className='btn btn-outline btn-primary'>NT</button>
            <button onMouseOver={() => {
                map.current.setFeatureState(
                    {source: 'states', id: 3},
                    {hover: true}
                );
            }} onMouseLeave={() => {
                map.current.setFeatureState(
                    {source: 'states', id: 3},
                    {hover: false}
                );
            }} className='btn btn-outline btn-primary'>SA</button>
            <button onMouseOver={() => {
                map.current.setFeatureState(
                    {source: 'states', id: 4},
                    {hover: true}
                );
            }} onMouseLeave={() => {
                map.current.setFeatureState(
                    {source: 'states', id: 4},
                    {hover: false}
                );
            }}  className='btn btn-outline btn-primary'>WA</button>
            </div>
      </div>
    </div>
    <Footer />
    </>
  );
}

export default GetLicensed;
